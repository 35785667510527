@tailwind base;

@tailwind components;

@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto+Serif:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Monoton&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bungee+Shade&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bungee+Shade&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap");

body {
  font-family: "Roboto", serif;
}

.pagination .page-link {
  @apply px-3 py-1 border border-gray-300  text-gray-700;
}

.pagination .page-link:hover {
  @apply bg-gray-200;
}

.pagination .active .page-link {
  @apply bg-blue-500 text-white border-blue-500;
}

.pagination .disabled .page-link {
  @apply cursor-not-allowed text-gray-400;
}
html {
  scrollbar-width: thin;
  scrollbar-color: #3182ce #f1f1f1;
}
::-webkit-scrollbar {
  width: 10px;
}

/* For WebKit browsers (like Chrome and Safari) */
::-webkit-scrollbar-thumb {
  background-color: #3182ce;
}

/* For WebKit browsers (like Chrome and Safari) */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
/* Custom class for scrollbar */
.scrollbar-custom {
  /* For WebKit browsers (like Chrome and Safari) */
  scrollbar-width: thin;
  scrollbar-color: #3182ce #f1f1f1;

  /* For WebKit browsers (like Chrome and Safari) */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* For WebKit browsers (like Chrome and Safari) */
  ::-webkit-scrollbar-thumb {
    background-color: #3182ce;
  }

  /* For WebKit browsers (like Chrome and Safari) */
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
}

.pdf-container {
  /* Reset margin and padding */
  margin: 0;
  padding: 0;
}

.selected {
  background-color: lightblue;
}

.error-message {
  @apply text-red-500 font-semibold;
}
